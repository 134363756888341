<!-- Copyright 2020 Alta Voce, France - Any unauthorized use is NOT permitted. -->
<template>
  <section class="padded transparent main">
    <field-errors :errors="errors.nonFieldErrors" />
    <loading-area class="opaque padded bordered" v-if="isLoading" :is-loading="isLoading">
      <span><translate translate-context="*/*/Loader">Loading…</translate></span>
    </loading-area>
    <div v-else class="stackable row">
      <div class="three wide column">
        <section class="sticky bordered padded">
          <h4>
            <translate translate-context="*/*/Title/Name">
              Contents
            </translate>
          </h4>
          <toc v-if="renderToc" :content-element="$refs.content" :level="2" />
        </section>
      </div>
      <div class="nine wide column">
        <section class="heavily bordered padded" ref="content">
          <h1><translate translate-context="*/*/*/Noun">Alta Call - Privacy policy</translate></h1>
          <div class="discrete">
            <translate translate-context="*/*/*/Noun">Last modified on:</translate> <time
              :title="terms.altacall_privacy_policy.last_update"
              :datetime="terms.altacall_privacy_policy.last_update">
                {{ prettyDate(terms.altacall_privacy_policy.last_update) }}
            </time>
          </div>
          <p>
            <translate translate-context="*/*/*" :translate-params="{email: terms.altacall_privacy_policy.contact.email}">
              If you have any question regarding our privacy policy, or if you want to ask for deletion
              of your data, please reach out at <a href="mailto:%{ email }">%{ email }</a>
            </translate>
          </p>
        
          <div v-html="terms.altacall_privacy_policy.content"></div>
          <h2>Definitions</h2>
          <ul>
            <li><strong>Client</strong>: The entity (moral or physical), who buys Alta Voce’s software.</li>
            <li><strong>User</strong>: The person whose voice is transformed by Alta Voce technologies.</li>
            <li><strong>Executable Software</strong>: The software that is installed locally on the agent’s computer.</li>
            <li><strong>Client Space</strong>: The website that allows the Client to manage their Executable Software installations.</li>
          </ul>

          <h2>Consent</h2> 
          <p>Users can grant or remove consent for some or all types of data collection. Such consent is processed and stored in our database so that all our applications can behave accordingly and comply with the User’s choice.</p>
          <h2>Data request and removal</h2>
          <p>Users and Clients can contact us by email to request access to their data, or deletion of this data.</p>
          <p>When we receive such requests, we first ensure the request comes from the adequate party, by verifying several identifiers, then our infrastructure team proceed to the extraction or deletion of the data through scripts that are written and tested for this purpose.</p>
          <h2>Purpose for collecting information</h2>
          <p>We collect information about the User and the User’s machine in order to:</p>
          <ul>
            <li>Verify the good behavior of our software. The audio processing being a real-time critical operation, the health of this processing is of prime importance.</li>
            <li>Improve our software to make it more efficient, easier to handle.</li>
            <li>Fine-tune our voice transformations based on a user voice.</li>
            <li>Improve our technology through an R&D program that can rely on real-world data.</li>
          </ul>
          <h2>Retention</h2>
          <p>We only collect and keep the data that is necessary for our services to work. This is enforced through several automatic tasks and constraints, both at the application and database level:</p>
          <ul>
            <li>error and crash reports are automatically purged after 90 days</li>
            <li>user and/or client deletion automatically cascade to related rows in the database</li>
            <li>expired data such as telemetry entries, audio recordings and support requests is purged from our database on a daily basis, through automated tasks</li>
            <li>within the Executable Software, the audio stream is lost after 50 milliseconds. The audio stream is never transmitted to Alta Voce’s server.</li>
          </ul>
          <h2>Information we collect and process</h2>
          <h3>
            From the Executable Software
          </h3>
          <p>We process telemetry information that is collected by the Executable Software and sent to our servers. This telemetry information consists mainly of:</p>
          <h4>Software information</h4>
          <ul>
            <li>Version</li>
            <li>Memory usage </li>
            <li>CPU usage</li>
          </ul>

          <h4>Transformation process information</h4>
          <ul>
            <li>Process health status.</li>
            <li>Transformation usage, consumption.</li>
            <li>Algorithm parameters.</li>
            <li>Long-term Audio Measures for algorithm parameterization (see below).</li>
          </ul>

          <h4>Audio processing</h4>
          <ul>
            <li>Available devices.</li>
            <li>Devices used.</li>
            <li>Saturation, over/underrun status, status information.</li>
          </ul>
          <h4>Audio recordings</h4>
          <ul>
            <li>Short audio segments (less than a couple minutes) with and without voice transformation applied.</li>
          </ul>

          <h4>Networking: consumption and potential errors</h4>

          <h4>GUI: Current configuration in order to restore it later on.</h4>

          <h4>Operating System</h4>
          <ul>
            <li>Name (Windows, Linux, MacOS)</li>
            <li>Version, architecture</li>
            <li>Local IP address</li>
            <li>Web IP address</li>
            <li>Time zone</li>
            <li>Computer brand</li>
            <li>CPU model</li>
            <li>Session: active, locked, administrator mode</li>
          </ul>

          <h4>User identifier (pseudo-anonymised or clear depending on User’s Consent).</h4>
          <h4>Machine identifier (pseudo-anonymised)</h4>

          <h4>
            Regarding long-term Audio Measures
          </h4>
          <p>
            It contains audio statistical values (ex. averages) that are computed over minutes of recordings. The corresponding time resolution being too low, this does <em>not</em> allow to reconstruct the meaning of any dialog. This is only correlated to the person speaking. Additionally, many people sharing the same Long-term Audio Measures, it is <em>not</em> possible to uniquely identify a person through those Long-term Audio Measures.
          </p>
          <h3>
            From our Client Space
          </h3>
          <p>To operate the Client Space, Alta Voce stores the following personal information in its database:</p>
          <ul>
            <li>Account email</li>
            <li>Account first and last name</li>
            <li>Connexion logs</li>
          </ul>

          <p>No cookie is used when the Client Space is accessed through a web browser. Some data is stored in the browser through the LocalStorage mechanism, in particular:</p>
          <ul>
            <li>A connexion token, which is required for authentication</li>
            <li>Cached data to improve the user experience and reduce the load on the device</li>
          </ul>
          <h2>Communication</h2>
          <p>Responsible persons at Alta Voce:</p>
          <ul>
            <li>Data Protection Officer (DPO) · Gilles Degottex <a href="mailto:gilles.degottex@alta-voce.tech">gilles.degottex@alta-voce.tech</a></li>
            <li>Responsible of GDPR compliance across the organization · Gilles Degottex <a href="mailto:gilles.degottex@alta-voce.tech">gilles.degottex@alta-voce.tech</a></li>
          </ul>

          <h2>Third parties</h2>
          <p>Collected data may be processed by the following third-parties, in compliance with GDPR:</p>
          <table>
            <thead>
              <th>Name</th>
              <th>Role</th>
              <th>Processed data</th>
              <th>Country</th>
              <th>Applicable regulations and guarantees</th>
            </thead>
            <tbody>
              <tr>
                <td><a href="https://www.ovh.com/fr/support/mentions-legales/">OVH SAS</a></td>
                <td>Hosting provider</td>
                <td>All data collected by the Executable and the Client Space</td>
                <td>France</td>
                <td>GDPR</td>
              </tr>
              <tr>
                <td><a href="https://about.gitlab.com/privacy/privacy-compliance/">GitLab Inc</a></td>
                <td>Support provider</td>
                <td>Support requests and data removal requests</td>
                <td>USA</td>
                <td>GDPR</td>
              </tr>
              <tr>
                <td><a href="https://www.mailgun.com/gdpr/">Mailgun Technologies Inc.</a></td>
                <td>Email provider</td>
                <td>Email messages sent by our applications</td>
                <td>USA</td>
                <td>EU-US Privacy Shield Framework</td>
              </tr>
            </tbody>
          </table>
          <p>Except from the processors listed above, Alta Voce does not transmit its data to any other company, nor freely or for any kind of reward.</p>

          <h2>Data breach</h2>
          <p>In case of a breach, the following Supervisory Authority will be notified within 72 hours:</p>

          <p>
            Commission Nationale de l’Informatique et des Libertés – CNIL<br>
            8 rue Vivienne, CS 30223<br>
            F-75002 Paris, Cedex 02<br>
            Tel. +33 1 53 73 22 22<br>
            Fax +33 1 53 73 22 00<br>
            <a href="http://www.cnil.fr/">https://www.cnil.fr/</a>
          </p>
        </section>
      </div>
    </div>
  </section>
</template>

<script>
import http from '@/http'
import {prettyDate} from '@/time'
import Toc from '@/components/TOC'

export default {
  components: {
    Toc,
  },
  data () {
    return {
      terms: null,
      errors: {},
      isLoading: false,
      prettyDate,
      renderToc: false
    }
  },
  async created () {
    await this.fetchData()
  },
  computed: {
    grants () {
      let c = [
        {id: "data_collection", optional: false},
        {id: "anonymized_data", optional: true},
        {id: "recording", optional: true},
      ]

      return c.map((i) => {
        return {
          optional: i.optional,
          ...this.terms.altacall_privacy_policy.grants[i.id]
        }
      })
    }
  },
  methods: {
    async fetchData () {
      let response
      this.isLoading = true
      this.errors = {}
      this.versions = []
      try {
        response = await http.get("it/terms")
        this.terms = response.data
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
      this.$nextTick(() => {
        this.renderToc = true
      })
    }
  }
}
</script>